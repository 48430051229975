import type { DataStructure } from "@redux/plugins/data-fetcher/interface";
import type { DEFAULT_STATUS } from "@redux/utils/helpers";

import type { TaxOperationCode } from "@services/tax-models/interface";

import type { CompanyBranch } from "@old/services/companies/interface";
import type { Consumer } from "@old/services/consumers/interface";

export interface State {
    listings: {
        buyers: DataStructure<
            Pick<Consumer, "ConsumerNameCorporateName" | "IDConsumer">[]
        >;
        issuers: DataStructure<
            Pick<CompanyBranch, "AccountName" | "IDCompany">[]
        >;
        taxOperationCodes: DataStructure<
            Pick<TaxOperationCode, "description" | "id" | "operationCode">[]
        >;
    };
    message: string | null;
    status: DEFAULT_STATUS;
}

export const moduleId = "taxesModelsModelTemplates" as const;
