import type { PaginationState } from "@redux/plugins/pagination-manager/interface";
import type { DEFAULT_STATUS } from "@redux/utils/helpers";

import type { NfeTemplateGroup } from "@services/nfe/templates/interface";
import type { TaxModel } from "@services/tax-models/interface";

export interface TaxModelItem
    extends Pick<TaxModel, "default" | "description" | "id" | "name" | "type"> {
    templatesCount: number;
}

export interface TaxModelComplement {
    templateGroups: NfeTemplateGroup[];
}

export type State = {
    message: string | null;
    status: DEFAULT_STATUS;
    taxModels: PaginationState<TaxModelItem, TaxModelComplement>;
};

export const moduleId = "taxesModels" as const;
