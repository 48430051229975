import type { DataStructure } from "@redux/plugins/data-fetcher/interface";

import type { TaxModel } from "@services/tax-models/interface";

export type TaxModelDraftData = Partial<
    Pick<TaxModel, "default" | "description" | "name" | "type">
>;

export interface TaxModelData extends TaxModel {
    templatesCount: number;
}

export interface State extends DataStructure<TaxModelData, null> {
    draft: TaxModelDraftData;
}

export const moduleId = "taxesModelsModelMain" as const;
