import * as AccountPayloads from "./accounts/payloads/interface";
import * as Consumers from "./consumers/interface";
import * as Dashboard from "./dashboard/interface";
import * as HubOffers from "./hub/offers/interface";
import * as HubOffersCreate from "./hub/offers/create/interface";
import * as Orders from "./orders/interface";
import * as Skus from "./skus/interface";
import * as SkusCatalogGridLayout from "./skus/catalog/grid-layout/interface";
import * as SkusSkuSpecifications from "./skus/sku/specifications/interface";
import * as TaxesModels from "./taxes/models/interface";
import * as TaxesModelsModelMain from "./taxes/models/model/main/interface";
import * as TaxesModelsModelTemplates from "./taxes/models/model/templates/interface";
import * as Wallet from "./wallet/interface";

export const moduleRelation = {
    [AccountPayloads.moduleId]: "/accounts/payloads",
    [Consumers.moduleId]: "/consumers",
    [Dashboard.moduleId]: "/dashboard",
    [HubOffers.moduleId]: "/hub/offers",
    [HubOffersCreate.moduleId]: "/hub/offers/create",
    [Orders.moduleId]: "/orders",
    [Skus.moduleId]: "/skus",
    [SkusCatalogGridLayout.moduleId]: "/skus/catalog/grid-layout",
    [SkusSkuSpecifications.moduleId]: "/skus/sku/specifications",
    [TaxesModels.moduleId]: "/taxes/models",
    [TaxesModelsModelMain.moduleId]: "/taxes/models/model/main",
    [TaxesModelsModelTemplates.moduleId]: "/taxes/models/model/templates",
    [Wallet.moduleId]: "/wallet",
};

export interface RootState {
    [AccountPayloads.moduleId]?: AccountPayloads.State;
    [Consumers.moduleId]?: Consumers.State;
    [Dashboard.moduleId]?: Dashboard.State;
    [HubOffers.moduleId]?: HubOffers.State;
    [HubOffersCreate.moduleId]?: HubOffersCreate.State;
    [Orders.moduleId]?: Orders.State;
    [Skus.moduleId]?: Skus.State;
    [SkusCatalogGridLayout.moduleId]?: SkusCatalogGridLayout.State;
    [SkusSkuSpecifications.moduleId]?: SkusSkuSpecifications.State;
    [TaxesModels.moduleId]?: TaxesModels.State;
    [TaxesModelsModelMain.moduleId]?: TaxesModelsModelMain.State;
    [TaxesModelsModelTemplates.moduleId]?: TaxesModelsModelTemplates.State;
    [Wallet.moduleId]?: Wallet.State;
}
