import type {
    CreateSelectorSetupFunction,
    ExtendedSelector,
} from "./interface";

import { DEFAULT_STATUS } from "../helpers";
import type { StatusInfo, StatusState } from "../helpers";

export function getStatus<
    S,
    R,
    Extra extends Record<string, any> = NonNullable<unknown>,
>(
    creator: CreateSelectorSetupFunction<S | undefined, R>,
    mapState: (
        state?: S,
    ) => Pick<StatusState, "message" | "status"> | undefined,
    extraData?: (state?: S) => Extra,
): ExtendedSelector<R, StatusInfo & Extra, any[], "root"> {
    return creator(
        (state) => {
            const { status, message } = mapState(state) || {};
            const extra = extraData?.(state) || {};
            return [status, message || null, extra];
        },
        (status, message, extra) =>
            ({
                ...extra,
                failure: status === DEFAULT_STATUS.FAILURE,
                loading: status === DEFAULT_STATUS.LOADING,
                message,
                pending: !status || status === DEFAULT_STATUS.PENDING,
                processing: status === DEFAULT_STATUS.PROCESSING,
                success: status === DEFAULT_STATUS.SUCCESS,
            } as StatusInfo & Extra),
    );
}
