import type { FC } from "react";

import { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { formatTabId, parseTabId, useTabsContext } from "./helpers";
import type { TabInfo } from "./interface";

import ButtonIcon from "../ButtonIcon";
import {
    CloseIconInfo,
    MoreVerticalIconInfo,
    SpinnerIcon,
} from "../Icons/Mocks";
import Popover, {
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from "../Popover";

import useDragAndDrop from "@hooks/events/useDragAndDrop";
import useScrollOnWheel from "@hooks/events/useScrollOnWheel";

import { processRefList } from "@utils/react/refProcessing";
import { classes } from "@utils/styles/tailwind/v4";

interface TabLabelProps extends Pick<TabInfo, "closable" | "key" | "label"> {
    id: string;
    isDragging?: boolean;
    isLoading?: boolean;
    draggable?: boolean;
    selected?: boolean;
    onClick?: () => void;
    onClose?: () => void;
    classNameTab?: string;
}

const TabLabel = forwardRef<HTMLLIElement, TabLabelProps>(function Tablabel(
    {
        id,
        label,
        closable,
        draggable,
        selected,
        isDragging,
        isLoading,
        onClick,
        onClose,
        classNameTab,
    },
    ref,
) {
    const title = "Remover aba " + label;
    return (
        <li
            ref={ref}
            id={formatTabId(id)}
            role="tab"
            draggable={draggable}
            className={`relative flex items-center gap-4 min-w-max h-40 px-16 text-sm ${classNameTab} ${
                selected
                    ? "text-doladoBlue cursor-default after:absolute after:inset-x-0 after:bottom-0 after:border-b-2 after:border-doladoBlue"
                    : "text-grey cursor-pointer"
            }`}
            title={label}
            onClick={() => !selected && onClick?.()}>
            {isDragging && (
                <>
                    <div
                        id="left"
                        className="absolute inset-y-0 left-0 z-10 w-1/2"
                    />
                    <div
                        id="right"
                        className="absolute inset-y-0 right-0 z-10 w-1/2"
                    />
                </>
            )}
            <span className="max-w-256 line-clamp-1">{label}</span>
            {isLoading && <SpinnerIcon className="w-16 h-16 animate-spin" />}
            {closable && (
                <ButtonIcon
                    variant="unstyled"
                    size="unset"
                    icon={CloseIconInfo}
                    label={title}
                    title={title}
                    className="w-14 min-w-14 h-14 p-2"
                    iconStyle="text-grey-700"
                    onClick={(event) => {
                        event.stopPropagation();
                        onClose?.();
                    }}
                />
            )}
        </li>
    );
});

interface TabListProps {
    className?: string;
    isDraggable?: boolean;
    loadingTabs?: string[];
}

const TabList: FC<TabListProps> = ({
    className,
    isDraggable,
    loadingTabs = [],
}) => {
    const container = useRef<HTMLUListElement>(null);
    const tabRefs = useRef<(HTMLLIElement | null)[]>([]);

    const [showMore, setShowMore] = useState(false);
    const showMoreRef = useRef(showMore);
    const [showPopover, setShowPopover] = useState(false);
    const onOpenPopover = () => setShowPopover(true);
    const onClosePopover = () => setShowPopover(false);

    const { current, tabs, onChange, onClose, onCloseAll, onSort } =
        useTabsContext();

    const { hoverTarget, onDrag, onDragEnd, onDragStart, onDragOver } =
        useDragAndDrop({
            onDragStart: (dragged) => {
                if (dragged) dragged.style.opacity = "0";
            },
            onDragEnd: (target, dragged) => {
                const draggedTabElement = tabRefs.current.find(
                    (tab) => tab && tab.contains(dragged),
                );
                const targetTabElement = tabRefs.current.find(
                    (tab) => tab && tab.contains(target),
                );

                const getNext = target?.id === "right";

                const draggedSlug = parseTabId(draggedTabElement?.id || "");
                const targetSlug = parseTabId(targetTabElement?.id || "");

                if (draggedSlug !== targetSlug) {
                    onSort((curr) => {
                        const draggedTab = curr.find(
                            ({ slug }) => slug === draggedSlug,
                        );

                        let targetIdx = curr.findIndex(
                            ({ slug }) => slug === targetSlug,
                        );
                        if (targetIdx !== -1 && getNext) {
                            targetIdx++;
                        }

                        const targetTab = curr[targetIdx];

                        if (!draggedTab || (targetTab && !targetTab.draggable))
                            return null;

                        return {
                            from: draggedTab.key,
                            to: targetTab?.key || "",
                        };
                    });
                }

                if (dragged) dragged.style.opacity = "";
            },
        });

    useScrollOnWheel(container);

    useEffect(() => {
        if (!container.current) return;

        const observer = new ResizeObserver((entries) => {
            if (!entries[0]?.target) return;
            const { clientWidth, scrollWidth } = entries[0].target;

            const hasScroll = scrollWidth - clientWidth > 0;

            if (hasScroll && !showMoreRef.current) {
                showMoreRef.current = true;
                setShowMore(true);
            } else if (!hasScroll && showMoreRef.current) {
                showMoreRef.current = false;
                setShowMore(false);
                setShowPopover(false);
            }
        });

        observer.observe(container.current);
        return () => {
            observer.disconnect();
        };
    }, [tabs]);

    useEffect(() => {
        if (!hoverTarget) return;

        const tabRef = tabRefs.current.find(
            (tab) => tab && tab.contains(hoverTarget),
        );

        if (tabRef && tabRef.draggable) {
            if (hoverTarget.id === "left") {
                tabRef.style.borderLeft = "1px solid rgb(25 107 247)";
            }
            if (hoverTarget.id === "right") {
                tabRef.style.borderRight = "1px solid rgb(25 107 247)";
            }
        }

        return () => {
            if (tabRef) {
                tabRef.style.borderLeft = "";
                tabRef.style.borderRight = "";
            }
        };
    }, [hoverTarget]);

    const scrollToTab = (slug: string) => {
        if (!container.current) return;
        const id = formatTabId(slug);
        const tab = tabRefs.current.find((tab) => tab?.id === id);
        tab?.scrollIntoView({ inline: "center" });
    };

    const processRef = useMemo(() => processRefList(tabRefs), []);

    const canClose = useMemo(
        () => tabs.some(({ closable }) => closable),
        [tabs],
    );

    return (
        <div
            className={classes`relative w-full h-40 mb-16 border-b border-b-gray-200 pr-48 ${className}`}>
            <ul
                ref={container}
                role="tablist"
                className="absolute inset-0 flex mb-0 pl-0 pr-64 overflow-hidden"
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrag={onDrag}
                onDragOver={onDragOver}>
                {tabs.map(({ key, slug, label, closable, draggable }) => (
                    <TabLabel
                        ref={processRef}
                        key={key}
                        draggable={isDraggable && draggable}
                        isDragging={!!hoverTarget && draggable}
                        isLoading={loadingTabs.includes(key)}
                        id={slug}
                        selected={key === current}
                        label={label}
                        closable={closable}
                        onClick={() => (onChange(key), scrollToTab(slug))}
                        onClose={() => onClose(key)}
                        classNameTab={className}
                    />
                ))}
                {canClose && tabs.length > 5 && (
                    <TabLabel
                        key="close"
                        id="close"
                        label="Fechar abas"
                        onClick={onCloseAll}
                        classNameTab={className}
                    />
                )}
            </ul>
            {showMore && (
                <Popover
                    className="absolute right-0 inset-y-0 w-40"
                    size="md"
                    position="bottom-end"
                    isOpen={showPopover}
                    onClose={onClosePopover}>
                    <PopoverTrigger>
                        <ButtonIcon
                            type="button"
                            label="show-more"
                            title="Ver todas as abas"
                            icon={MoreVerticalIconInfo}
                            variant="unstyled"
                            size="unset"
                            className="bg-lightGrey w-full h-full p-8 shadow-[-6px_0px_6px_-4px_rgba(0,0,0,0.15)]"
                            onClick={onOpenPopover}
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        {canClose && (
                            <PopoverHeader
                                hideButton
                                className="px-8 pt-8 pb-8">
                                <p
                                    className="m-0 text-base font-medium cursor-pointer"
                                    onClick={() => (
                                        onCloseAll(), onClosePopover()
                                    )}>
                                    Fechar abas
                                </p>
                            </PopoverHeader>
                        )}
                        <PopoverBody className="p-0 pt-8">
                            <ul className="max-h-200 space-y-4 mb-0 p-0 overflow-y-auto scrollbar-floating">
                                {tabs.map(({ key, slug, label }) => (
                                    <li
                                        key={key}
                                        className="px-8 text-base font-medium cursor-pointer hover:bg-gray-100"
                                        onClick={() => (
                                            onChange(key),
                                            scrollToTab(slug),
                                            onClosePopover()
                                        )}>
                                        {label}
                                    </li>
                                ))}
                            </ul>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            )}
        </div>
    );
};

export default TabList;
