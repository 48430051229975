import { DEFAULT_STATUS } from "@redux/utils/helpers";
import type { PersistentState } from "@redux/utils/interface";

import { GetAllCompanyWalletRecordsParams } from "@services/companies/wallet/getAllWalletRecords";
import {
    WalletAllRecordData,
    WalletStatusInfo,
} from "@services/companies/wallet/interface";

import type { FilterState } from "@redux/plugins/filter-manager/interface";
import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "@components/FilterDrawer/interface";

export interface FilterExtraData {
    adminOnly?: boolean;
}

export type State = PersistentState<{
    companyWallet: {
        data: WalletStatusInfo[];
        status: DEFAULT_STATUS;
    };
    wallet: {
        data: (WalletAllRecordData | null)[];
        extraData: Record<string, any>;
        pageInfo: {
            limit: number;
            page: number;
            total: number;
        };
        query: Omit<GetAllCompanyWalletRecordsParams, "limit" | "page">;
        // pagination: PaginationState;
        filters: FilterState<
            FilterDrawerExtraData & FilterExtraData,
            FilterDrawerGroupExtraData
        >;
        status: DEFAULT_STATUS;
    };
}>;

export const moduleId = "wallet" as const;
