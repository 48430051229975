import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "@components/FilterDrawer/interface";

import type { SkuTypeFilterLoaderParams } from "@redux/filters/sku";

import type { FilterState } from "@redux/plugins/filter-manager/interface";
import type { PaginationState } from "@redux/plugins/pagination-manager/interface";

import type {
    GetHubOffersParams,
    HubSkuItem,
    HubOfferItem,
} from "@old/services/hub/offers/getAll";
import type { HubOffer, HubSku } from "@old/services/hub/offers/interface";

export type PaginationParams = Partial<Omit<GetHubOffersParams, "Page">>;

export interface OfferItem
    extends Pick<
        HubOfferItem,
        | "AccountName"
        | "AdvertisementStatus" // extra para sincronia de anúncios
        | "AdvertisementUrl"
        | "CatalogEligible" // extra para sincronia de anúncios
        | "CatalogListing" // extra para sincronia de anúncios
        | "CatalogStatus" // extra para sincronia de anúncios
        | "Health"
        | "IDCompanyIntegration"
        | "IDHubProduct"
        | "IDProduct"
        | "IDProductHub"
        | "IDSkuCompany"
        | "IDTypeCompanyIntegration" // extra para duplicar anúncio
        | "IntegrationName"
        | "ListingTypeDescription"
        | "LogoUrl"
        | "MainImageThumbnailURL"
        | "SkuName"
        | "TypeProduct"
    > {
    HubSku: Pick<
        HubSkuItem,
        | "IDHubAdvertisement" // extra para atualização de preço de variação
        | "IDSku" // extra para atualização de preço de variação
        | "MainImageURL"
        | "SkuName"
    >[]; // extra para sincronia de anúncios
}

export interface OfferItemComplement
    extends Pick<
        HubOffer,
        | "Catalog"
        | "CatalogEligible"
        | "CatalogListing"
        | "ShippingLogisticValue"
        | "ShippingLogisticType"
        | "ShippingTags"
        | "ShippingTagsValue"
    > {
    HubSku: Pick<
        HubSku,
        | "BarCode"
        | "IDSku"
        | "IDSkuHub"
        | "IDSkuCompany"
        | "MainImageURL"
        | "PricingCheck"
        | "PricingSell"
        | "PricingSet"
        | "ProductVariation"
        | "QtyAvailable"
        | "SkuName"
        | "TypeProductVariationValue"
    >[];
}

export interface FilterExtraData {
    adminOnly?: boolean;
}

export type FilterLoaderParams = SkuTypeFilterLoaderParams;

export interface State {
    filters: FilterState<
        FilterExtraData & FilterDrawerExtraData,
        FilterDrawerGroupExtraData
    >;
    offers: PaginationState<OfferItem, OfferItemComplement, PaginationParams>;
}

export const moduleId = "hubOffers" as const;
