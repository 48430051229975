import { Navigate, Outlet } from "react-router-dom";
import type { RouteObject } from "react-router-dom";

import { getPathEnd, getPathDiff } from "./helpers";
import { routeNames } from "./interface";

import PrivateRoute from "@components/Session/PrivateRoute";
import { withPublicRoute } from "@components/Session/PublicRoute";
import OnboardingTermsOfUseTrigger from "@components/Onboarding/TermsOfUseTrigger";

import MenuContextProvider from "@contexts/menu";
import ModalManagerProvider from "@contexts/modal-manager-v2";
import NotificationManager from "@contexts/notification-manager";
import OnboardingContextProvider from "@contexts/onboarding";
import ThemeProvider from "@contexts/theme";

import AppModuleManager from "@modules";

import MainPage from "@pages/main";
import PasswordForgotPage from "@pages/password/forgot";
import PasswordResetPage from "@pages/password/reset";
import SignInPage from "@pages/signin";
import SignUpPage from "@pages/signup";
import InnerTestsPage from "@pages/inner-tests";
import OuterTestsPage from "@pages/outer-tests";

import { MainContextProvider } from "@old/contexts/MainContext";

const modulesRoutes = [
    routeNames.APP_HOME,
    routeNames.APP_CONSUMERS_DETAILS,
    routeNames.APP_OFFERS_DETAILS,
    routeNames.APP_ORDERS_DETAILS,
    routeNames.APP_PRODUCT_DETAILS,
    routeNames.APP_TAXES_MODELS_DETAILS,
];

export const routes: RouteObject[] = [
    {
        path: getPathEnd(routeNames.LOGIN),
        Component: withPublicRoute(SignInPage),
    },
    {
        path: getPathEnd(routeNames.SIGNUP),
        Component: withPublicRoute(SignUpPage),
    },
    {
        path: getPathEnd(routeNames.PASSWORD_FORGOT),
        Component: withPublicRoute(PasswordForgotPage),
    },
    {
        path: getPathEnd(routeNames.PASSWORD_RESET),
        Component: withPublicRoute(PasswordResetPage),
    },
    {
        path: "*",
        element: (
            <ThemeProvider>
                <Outlet />
            </ThemeProvider>
        ),
        children: [
            {
                path: getPathEnd(routeNames.APP_ROOT),
                Component: () => (
                    <PrivateRoute>
                        <AppModuleManager>
                            <NotificationManager>
                                <MenuContextProvider>
                                    <MainContextProvider>
                                        <OnboardingContextProvider>
                                            <ModalManagerProvider>
                                                <OnboardingTermsOfUseTrigger />
                                                <Outlet />
                                            </ModalManagerProvider>
                                        </OnboardingContextProvider>
                                    </MainContextProvider>
                                </MenuContextProvider>
                            </NotificationManager>
                        </AppModuleManager>
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: "testes",
                        element: <InnerTestsPage />,
                    },
                    ...modulesRoutes.map<RouteObject>((route) => ({
                        path: getPathDiff(routeNames.APP_ROOT, route).slice(1),
                        Component: MainPage,
                    })),
                    {
                        path: "*", // getPathEnd(routeNames.APP_HOME),
                        Component: MainPage,
                    },

                    // {
                    //     path: "*",
                    //     element: <Navigate to={routeNames.APP_HOME} />,
                    // },
                ],
            },
            {
                path: "testes",
                element: <OuterTestsPage />,
            },
            {
                path: "*",
                element: <Navigate to={routeNames.APP_ROOT} />,
            },
        ],
    },
];
