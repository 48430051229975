export enum DEFAULT_STATUS {
    FAILURE = "failure",
    HYDRATING = "hydrating",
    IDLE = "idle",
    LOADING = "loading",
    PENDING = "pending",
    PROCESSING = "processing",
    SUCCESS = "success",
}

export interface PageInfo {
    page: number;
    pageSize: number;
    pages: number;
    totalCount: number;
}

export interface StatusState {
    message: string | null;
    status: DEFAULT_STATUS;
    requestId: string | null;
}

export interface StatusInfo {
    failure: boolean;
    loading: boolean;
    message: string | null;
    pending: boolean;
    processing: boolean;
    success: boolean;
}
